






import { User } from '@app/models';
import { debounce } from '@bcase/core';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

@Component
export default class Account extends Vue {
  public settings: User.Settings = {
    gender: undefined,
    mfa: false,
    name: { display: '', first: '', last: '', middle: '' },
    phone: '',
  };

  private initialized = false;
  private debouncedSave = debounce(this.save.bind(this), 2000);

  created() {
    if (!this.$user.data) return;

    // Extract settings from user account
    this.settings.gender = this.$user.data.gender;
    this.settings.mfa = this.$user.data.mfa;
    this.settings.name = { ...this.$user.data.name };
    this.settings.phone = this.$user.data.phone;

    this.$nextTick().then(() => (this.initialized = true));
  }

  @Watch('settings', { deep: true })
  public watchSettings(value: User.Settings) {
    if (this.initialized) this.debouncedSave();
  }

  private async save() {
    const form = this.$el.querySelector('bce-form');
    const errors = form && (await form.validate(true));
    if (errors && errors.length) return;

    // Create display name
    const { first, middle, last } = this.settings.name;
    this.settings.name.display =
      [first, middle, last].filter(part => !!part).join(' ') ||
      (this.$user.data && this.$user.data.email) ||
      '';

    const settings = { ...this.settings };
    if (!settings.gender) delete settings.gender;

    // Store in database
    await this.$firebase
      .doc('/user/' + this.$user.id)
      .set(settings, { merge: true });

    this.$bce.message(this.$t('changes-saved'), 1, 'primary');
  }
}
